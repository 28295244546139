<template>
    <!--====== NAVBAR ONE PART START ======-->


    <nav class="navbar navbar-expand-lg fixed-top " :class="navbarClass">
        <div class="container">
            <a class="navbar-brand" @click="goToHome()">
                <img src="../assets/logo.png" width="90" alt="Logo" />
            </a>

            <button class="btn btn-primary navbar-toggler" type="button" data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
                <i class="fa-solid fa-bars"></i>
            </button>

            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav ms-auto">


                    <div class="two-item d-flex">
                        <li class="nav-item" @click="goToHome()">
                            <a @click="goToHome()">Home</a>
                        </li>
                        <li class="nav-item" @click="navigateToPage('services')">
                            <router-link to="/services">Services</router-link>
                        </li>
                    </div>

                    <li class="nav-item" @click="navigateToPage('works')">
                        <router-link to="/works">Portfolio</router-link>
                    </li>
                    <li class="nav-item" @click="navigateToPage('about')">
                        <router-link to="/about">About</router-link>
                    </li>
                    <li class="nav-item" @click="navigateToPage('contact')">
                        <router-link to="/contact">Contact</router-link>
                    </li>

                </ul>
            </div>
        </div>
    </nav>



    <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
        <div class="offcanvas-header">
            <h5 class="offcanvas-title" id="offcanvasExampleLabel">
                <a @click="goToHome()">
                    <img src="../assets/logo_color.png" alt="Logo" width="60" />
                </a>
            </h5>
            <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close">X</button>
        </div>
        <div class="offcanvas-body">

            <ul class="navbar-nav ms-auto">

                <li class="nav-item">
                    <a @click="goToHome()">Home</a>
                </li>
                <li class="nav-item">
                    <router-link to="/services">Services</router-link>
                </li>
                <li class="nav-item">
                    <router-link to="/works">Works</router-link>
                </li>
                <li class="nav-item">
                    <router-link to="/about">About</router-link>
                </li>
                <li class="nav-item">
                    <router-link to="/contact">Contact</router-link>
                </li>

            </ul>

        </div>
    </div>



    <!--====== NAVBAR ONE PART ENDS ======-->

    <!--====== Bootstrap js ======-->
</template>


<script>
export default {
    data() {
        return {
            scrollPosition: 0,
        }
    },
    methods: {
        goToHome() {
            window.location.href = "/"
        },
        navigateToPage(name) {
            this.$router.push('/' + name)
        },
        updateScroll() {
            this.scrollPosition = window.scrollY || document.documentElement.scrollTop;
        }
    },
    mounted() {
        window.addEventListener('scroll', this.updateScroll);
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.updateScroll);
    },

    computed: {
        navbarClass() {
            return this.scrollPosition > 730 ? 'navbar-bg' : '';
        }
    }
};
</script>



<style scoped>
.navbar-nav .nav-item {
    background: white !important;
    padding: 10px 20px !important;
    border-radius: 20px !important;
    margin: 0px 8px !important;
    font-size: 17px !important;
    cursor: pointer !important;
}

.navbar-nav .nav-item a {
    color: #490E74 !important;
    text-transform: uppercase !important;
    font-family: Poppins-Regular !important;
}


.navbar {
    background: transparent !important;
}

.navbar-bg {
    background: #0a082d !important;
}

@media (max-width: 767px) {
    .navbar-brand img {
        width: 50px !important;
    }
}


.two-item {
    background: white !important;
    border-radius: 20px !important;

}

.offcanvas {
    background: #0a082d !important;
}

.offcanvas .navbar-nav .nav-item {
    background: #0a082d !important;
    color: white !important;
}

.offcanvas .navbar-nav .nav-item a {
    color: white !important;
}


.navbar-brand {
    cursor: pointer !important;
}
</style>