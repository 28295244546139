<template>
  <div>
    <div class="overlay">
      <canvas id="cursor-canvas"></canvas>
    </div>
    <router-view />
  </div>
</template>



<script>
import { CursorCanvas } from './assets/js/new.js'
export default {
  mounted() {
    CursorCanvas()
  }
}

</script>


<style>
.carousel__prev,
.carousel__next {
  color: white !important;
}

.overlay {
  position: fixed !important;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh !important;
  width: 100% !important;
  background: transparent !important;
  z-index: -1;

}



.offcanvas-backdrop {
  background: none !important;
}
</style>






